<template>
  <div class="vip-page">
    <div class="vip-header">
      <img width="100%"
        src="//download-cos.yofish.com/y-taskcenter-gongjushiyebu/20220113142606877-headBg.png">
      <div class="user-msg">
        <div class="avator">
          <img :src="userInfo.icon" alt="">
          <div class="avator-icon" v-if="userInfo.userType === 1">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAA
            hFBMVEUAAAD////////////////////////////////////////////owHXu05/pw33qxoLv1aTv16fs
            zJDnvW/w2Kvt0Zzt0Jj+/fvw2q7ryYrry43tzZPqyIb9+vX79uvsz5X15sj68uL47Nbz4L3579336tP0
            48Hy3bX8+O/69Of258zy3rcj1X6eAAAAC3RSTlMA4wv573hjYDyqq+AMEAoAAAE6SURBVCjPhdPZdoIw
            FIVhwAH1Z0aIQ0FGsfb9369Hgg32QrnkWzsr2Tmxnp/jbm0Ae+s6+o+h1RJQRaGA5eqFNzZ8V/E5OR3z
            tgZ7Y2y9oByy/PLArzD0axbrP4Mm8g0GwRUm3SyoIv8FvT2LcWXHpon/o9diO4Iryv0c876sPM+rWUlw
            yVWjP1we2EAieGbpWC7lQTCKC4gEc0UTCKYdrrWjF5Sk4I9gD1kglh7YWjatxhqq5CTBOhiTIbaFOmis
            oEtOd4iDMZkqLIoJB7glF0UZCj70NkNfoc4VDIJiGtWEWQl7RXF8JhVmQ1kP4tcwNBva6qNI8S2ASiYc
            jyIlTMkI4D51m44lmPryAlSuUdc3L76D5qhRFz+/srjqovmVvbvsd2PyecDMaEaz0fw81IbdnX4OO/Mc
            fgFTzSyXb3yjjgAAAABJRU5ErkJggg==" alt="">
          </div>
        </div>
        <div class="user-text">
          <p class="username">{{ userInfo.crealName }}</p>
          <p class="vip-type"
            v-if="userInfo.userType === 1 && userInfo.vipType === 8">永久会员</p>
          <p class="vip-type"
            v-if="userInfo.userType !== 1">当前账户未开通会员权益 | 开通会员可享去广告等20项专属权益</p>
        </div>
      </div>
    </div>
    <div class="vip-userinfo">
      <ul>
        <li>
          <div @click="jumpAction('vip')" class="user-model">
            <p class="num">
              <span class="bold">{{ userInfo.giveVipDay }}</span>天
            </p>
            <p class="text">累计赠送会员</p>
          </div>
        </li>
        <li>
          <div @click="jumpAction('gold')" class="user-model">
            <p class="num">
              <span class="bold">{{ userInfo.goldNum }}</span>个
            </p>
            <p class="text">当前金豆</p>
          </div>
        </li>
        <li>
          <div @click="jumpAction('money')">
            <p class="num">
              <span class="bold">{{ userInfo.accountBalance }}</span>元
            </p>
            <p class="text">钱包余额</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="vip-main">
      <div class="vip-main-head">
        <p class="title">免费领会员</p>
        <span class="desc">12万人已领取</span>
      </div>
      <card-box
        v-for="(item, index) in taskList"
        :key="`task_${index}`"
        :box-list="item"
        :class-type="item.bottomList ? 'vipBox' : ''"
        @click-action="cardClickAction">
        <div class="vip-bottom" slot="bottom" v-if="item.bottomList">
          <card-bottom :list="item.bottomList"
            @open-dialog="openDialog($event, item)" />
        </div>
      </card-box>
    </div>
    <!-- <div class="vip-main">
      <div class="vip-main-head">
        <p class="title">现金享不停</p>
      </div>
      <card-box
        v-for="(item, index) in taskList3"
        :key="`task_${index}`"
        :box-list="item"
        :class-type="item.bottomList ? 'vipBox' : ''"
        @click-action="cardClickAction">
        <div class="vip-bottom" slot="bottom" v-if="item.bottomList">
          <card-bottom :list="item.bottomList"
            @open-dialog="openDialog($event, item)" />
        </div>
      </card-box>
    </div> -->
    <div class="vip-main" v-if="taskList2.length">
      <div class="vip-main-head">
        <p class="title">拿金豆兑好礼</p>
      </div>
      <card-box
        v-for="(item, index) in taskList2"
        :key="`task_${index}`"
        :box-list="item"
        :class-type="item.bottomList ? 'vipBox' : ''"
        @click-action="cardClickAction">
        <div class="vip-bottom" slot="bottom" v-if="item.bottomList">
          <card-bottom :list="item.bottomList"
            @open-dialog="openDialog($event, item)" />
        </div>
      </card-box>
    </div>
    <div class="vip-main" v-if="false">
      <div class="vip-main-head">
        <p class="title">现金送不停</p>
      </div>
      <card-box v-for="(item, index) in taskList1"
        :key="`carBox_${index}`"
        :box-list="item"
        class-type="vipBox"
        @click-action="cardClickAction">
        <div class="vip-bottom" slot="bottom">
          <card-bottom :list="item.bottomList"
            @open-dialog="openDialog($event, item)" />
        </div>
      </card-box>
    </div>
    <div class="task-bottom">
      <p>注：活动最终解释权归有鱼记账官方所有，任务开始前必须查看活动细则，若有疑问请及时联系有鱼官方客服，客服微信：youyujizhang</p>
    </div>
    <div class="loading">
      <y-loading v-show="loadingVisible"></y-loading>
    </div>
    <div class="y-model" v-if="modelVisible"></div>
    <y-dialog :visible="visible" class="welfare">
      <div class="title" slot="header">
        {{ dialogType === 'approve' ? '扫码领取福利' : dialogType === 'rule' ? '活动细则' : '资料审核' }}
      </div>
      <div class="dialog-content">
        <div class="close" @click="visible = false"></div>
        <template v-if="dialogType === 'approve'">
          <div class="dialog-desc desc-left" v-html="dialogDesc.desc1"></div>
          <div class="qrcode">
            <!-- <img :src="dialogDesc.qrcodeUrl" alt=""> -->
            <img src="//download-cos.yofish.com/youyu-gongjushiyebu/20220506172936973-000.jpg"
               alt="">
          </div>
          <div class="dowload-btn" v-if="isDownloadImage" @click="dowloadImage">下载二维码</div>
          <p class="tips-text" v-else>请截图后去微信添加老师</p>
        </template>
        <template v-if="dialogType === 'rule'">
          <div class="dialog-desc" v-html="ruleDesc">
          </div>
        </template>
        <template v-if="dialogType === 'examine'">
          <div class="dialog-desc" v-html="examineDesc">
          </div>
          <div class="dialog-desc dialog-desc-content">
            <div class="y-item">
              <label class="y-item__label">手机号：</label>
              <div class="y-item__content">
                <div class="y-input">
                  <input type="text" v-model="phone" placeholder="请输入手机号" />
                </div>
              </div>
            </div>
            <div class="y-item">
              <label class="y-item__label">截图   ：</label>
              <div class="y-item__content">
                <div class="upload-image">
                  <div class="image"
                    v-for="(item, index) in uploadImage" :key="index">
                    <img :src="item" alt="">
                    <div class="close"
                      v-show="index === 1 || uploadImage.length === 1"
                      @click="removeImage(index)"></div>
                  </div>
                  <y-upload
                  v-if="visible"
                   @change="imageChange"
                   @image-upload="imageUpload"
                   v-show="uploadImage.length < 2"></y-upload>
                </div>
              </div>
            </div>
            <div class="button">
              <button class="y-button y-button--danger" @click="submitAction">提交</button>
            </div>
          </div>
        </template>
      </div>
    </y-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { getTaskStatusApi, getUserInfoApi } from '@/api/base1';
import { getAppStatus, watchVideo, callAppHandler } from '@/utils/app';
import { acvityVipType, acvityCardType, acvityPacketType } from './type';
import taskList from './type/task.json';
import { shareConfig, isString, userAgentType } from '@/utils';
import cardBottom from './components/card-bottom';
import cardBox from './components/card-box';
import YDialog from '@/components/dialog';
import YUpload from '@/components/upload';
import YLoading from '@/components/loading';

export default {
  name: 'TaskCenter',
  data() {
    return {
      vipDays: 0,
      advertisements: [],
      userInfo: {},
      visible: false,
      qrcodeUrl: require('@/assets/images/qrcode.png'),
      dialogType: '',
      uploadImage: [],
      fileList: [],
      phone: '',
      ruleDesc: '',
      examineDesc: '',
      dialogDesc: {},
      loadingVisible: true,
      modelVisible: false,
      userId: getAppStatus().cuserId,
      // userId: '607328d1-c101-4db5-919c-37098ff38846',
      appInfo: '{}',
    };
  },
  components: {
    cardBottom,
    YDialog,
    cardBox,
    YUpload,
    YLoading,
  },
  mounted() {
    document.title = '任务中心';
    const regetData = () => {
      this.getStatus();
      this.getUserInfo();
      if (!this.userId) {
        this.modelVisible = true;
        this.$toast({
          content: '请登录后访问',
          duration: 3000,
        });
        this.loadingVisible = false;
      }
    };
    window.indexPage = { // 此方法为了配合旧的看视频领会员的功能
      regetData,
    };
    if (this.isAndroid) {
      this.appInfo = window?.android?.jzAppInfo();
    }
    if (this.userId) {
      window.indexPage.regetData();
      this.appInfo = window?.ios?.jzAppInfo();
    } else {
      // this.modelVisible = true;
      // this.$toast({
      //   content: '请登录后访问',
      //   duration: 3000,
      // });
      // this.loadingVisible = false;
      callAppHandler('jzAppInfo', null, (res) => {
        this.appInfo = res;
      });
      callAppHandler('jzUserId', null, (res) => {
        this.userId = res;
        window.indexPage.regetData();
      });
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        this.phone = '';
        this.fileList = [];
        this.uploadImage = [];
      }
    },
  },
  computed: {
    userIds() {
      // return '3129b4b2-a736-4e9b-97d8-7a8ee7f357fe';
      return getAppStatus().cuserId;
    },
    isIos() {
      return /i(os|phone|pad)/i.test(navigator.userAgent);
    },
    isAndroid() {
      return /android/i.test(navigator.userAgent);
    },
    appInfos() {
      let res = '{}';
      if (window?.ios?.jzAppInfo) {
        res = window.ios.jzAppInfo();
      }
      if (window?.android?.jzAppInfo) {
        res = window.android.jzAppInfo();
      }
      return res;
    },
    isDownloadImage() {
      const { isIos, isAndroid } = this;
      if (isIos && window?.ios?.saveImageToPhotosAlbum) {
        return true;
      }
      if (isAndroid && window?.android?.saveWebImg) {
        return true;
      }
      return false;
    },
    vipIconUrl() {
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAMAAADUivDaAAABCFBMVEUAAAD/ugL/swD/iwP/igD/qQD/zQD/iwL/jAL/zQD/ugH/jAP/jAL/jQP/xwH/yAD/wAD/jQD/xgD/igD/hwD/zgD/uQL/zQD/zQD/iwP/uQL/uQL/uQL/jAL/jAL/iwP/jAP/iwP/uQL/zgD/jAL/xAH/iwP/zgD/yQD/zQD/iwP/zgD/jAL/zAD/zQD/ywD/zQD/zQD/jAP/uQH/zQD/mQP/zQD/iwP/ugL/zQD/zgD/ugL/zgD/jQP/sgP/zQD/zQD/ugT/jQT/jwT/jgb/zgD/1QD/uQL/zQD/iwP/yQD/ogL/kQL/swH/sAH/pQL/nwL/mgL/xQD/lQP/jgP/vAH/tgH/rQHb91PTAAAAR3RSTlMA6AT7Iwjq6NDOyZB8STosJBgSEAX+/Pr18/Hj3t7ZxLWkiW5qX1pXDu/u49/d2NTDvbyzs6+tq6mahoJ3YFNNRz86OS0fGEmHDoMAAAFkSURBVFjD7dPnUsJAGIXhI9KVjiBSRKn23ntdFwTEev93omIkQTYnQX85k+cCzsw7ux8cDsf/FNnKjpkInsKO6Iww163CmitIFloy44aleUF0pPQnYeEiJYgnKeUEuHhW0I5PIVD7gmn2JnwxEBUheEfPJn9P3qGZpO/JOzTjVzBxJLh7+W05AaVwSlBtqduDSj0nuAdpcEK+Je3Q5aGQEzY6dKp/vtbgOtKoAIXjO26gYzwEBdc2XXiRRmUoedbZhN7Bvmc8YKeD3/vtiumCkLo5EFGv2URTXyi6wEQW1AuN5/7CrAdceNqiw5+AlbM07SjEYK3COnxu2FEannjVFhavYc/h0IR255lL2HXws0N1GJxrd3Ci9bVQxQg8G4qOMkZSN57LIzktIr6qT7TJaTE3S/2JLjktKuI1dBST4Pi5tOlpcedprYOeFleb6nXkY/i90sfGGz0ta7WAd8cNh8PxF+9GX0jIU2WpSAAAAABJRU5ErkJggg==';
    },
    taskList() {
      let data = [];
      // const { taskFree } = this;
      const videoData = this.advertisements.find(
        (item) => item.type === 'video',
      );
      const vipChallenge = {
        iconImg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAMAAADUivDaAAAAZlBMVEUAAAD/pAD/uQL/uQH/ugH/uwL/swD/iwP/iwL/jQL/jAP/iwT/jAP/jAP/iAT/hwD/uQL/33v/iwP/1Fr/3XX/twL/vRD/rgL/kwL/ogL/lgL/tAL/nQL/jQP/qQL/pwL/mgL/kAIS6SJnAAAAEHRSTlMABvLPx2wa+OM1mYpQSToin9I21gAAAP5JREFUWMPtlgkOgjAURAUBwbUUStmX+19SpRiMwZZOExNi3wFems78ZHYWy2ZwfM8l33E931EYgj1RsQ/kbxAGhUP6Dp+swZcpvFUKT6ZwVylcmYKswyr0FFmSxh+kSaalSOIFEi1FuqRIf/EK9V9sMNR/UUQXxyxU1lMaBibVqnL64GRQcD7QJ0f8FbwZDYcr/BcdFYYznEghDGGEhspqYchvaC9YKQxlBVVLhDnSM6idc5i0gAveNsLQYTcyh9lw9MzYFObA0Utl/RRmBR77HCYjqKIVhpoRWFG/wsQVY6Va8ob2UCrysubKoYTPNXw0mk9X8wFtPuMtls1wB5rpcpChwwXEAAAAAElFTkSuQmCC',
        taskName: '记账 赢会员',
        desc: '每天记账，轻松领会员<br/>最多可免费领9天会员',
        type: 'vip',
        btnText: '去挑战',
      };
      data.push(vipChallenge);
      // if (this.advertisements.length && !this.isIos) {
      //   data.push({
      //     iconImg:
      //       'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAMAAADUivDaAAAA/
      // 1BMVEUAAAD/ugL/xAD/ugL/uQL/zQD/ugL/uQL/zgD/wQD/zAD/zAD/zgD/zQD/ugH/zQD/zgD/zgD/
      // uQP/vQD/yAD/zAD/2wD/xwD/zAD/uwH/uQL/zQD/uQH/zQD/zgD/ugL/zgD/ugL/zQD/xAD/uQL/
      // uwP/ugP/zwD/zgD/uQL/xwD/zgD/ygD/wQH/vgH/zAD/uQH/zgD/zQD/ugH/ugP/zQD/88f/uQL/
      // ywD/5oP/xAD/wQD/ugH/xwD/zwn/8sH/8Lb/4Wf/1Sr/0Rj/uQH/4nD/vQH/ygD/88b/77L/5H3/
      // 3VP/20n/2D3/1zb/0A//zQP/7an/7Kf/6Zf/6ZTYDVaKAAAANXRSTlMA7A728vKOiGQTEfDp3LK
      // LiIBkNjIFA/fo3snFt7OtaF/+9e+CWlZWU/39+O3o38nFtrGtYBQ4cSEAAAHLSURBVFjD7dXXUu
      // MwFIBhuSZOJwVID8sC27t8tJZNDQm9v/+z4AAzmFhHHOAS/7f2fGNVs6ysd9liM18ddMsl56tTK
      // ncHtXxzkb0kc9T/wOdz+iOTChRqRa6uWCuQhI0Sx1v+RBAWuL4FivBGY4M/n34sXolALGvntMop
      // 1XT7wSERRRMn8jzZ5u45Yoxw4jdPtHfob+1sKok+KrSe7Op9P277+EJBOOh5afBk//27rk4VRhO
      // fijQRd7ObIvLokqoJ/+Bob46oYsQfhPBT8zrAiJ8YEbd9whN1MaKME3Fn/LEyRnzTEjvJY4IRS+
      // SvKGLEF/JcLKFfQV6RVYxYJe+L7xjxQ01cp3dnGyM6QkFcKs6I+IURlYh4UqO/GOHK+ftiX31f
      // yBxGDENBurVEWMeIBgScUgBNjGhZoSAIIrTxv3wPJIGQUGFodYCAMAz4hxMFG8bBc8IYbJPhuQA
      // ghQYQk/iNHNPkGfEbYSAwIAjj54bHdK3BrFBGaUVEcgbAyjrTl4P7xhMZRNFUzJpOo0BOxnDfR8
      // YoBt7Kg6BtzdAIxjqj5Lk2Atg5jxEr1HsWzGf16iZ7Sa3G0K102ob12TLanYo7bLRYVlbWa7oFm
      // jhFHy1tQ64AAAAASUVORK5CYII=',
      //     taskName: '看视频赢会员',
      //     desc: '看2次视频领1天会员 <br>限领2天会员/天',
      //     progress: `<i class="icon"><i>今日完成${videoData.todayCounts}/${videoData.threshold}次`,
      //     type: 'video',
      //     btnText: videoData.todayCounts < videoData.threshold ? '看视频' : '已完成',
      //     isComplete: videoData.todayCounts >= videoData.threshold,
      //     completeText: `今日完成${videoData.todayCounts}/${videoData.threshold}次`,
      //   });
      // }
      // data = data.concat(taskFree);
      return data;
    },
    taskList1() {
      return taskList.cash;
    },
    taskList2() {
      const goldList = taskList.gold;
      let { isAndroid } = userAgentType();
      // isAndroid = true;
      if (!isAndroid) {
        let index = goldList.findIndex((item) => !!item.isAndroid === !isAndroid);
        goldList.splice(index, 1);
      }
      // return goldList;
      return []; // 临时写死
    },
    taskList3() {
      return taskList.box;
    },
    taskFree() {
      return taskList.free;
    },
    taskMoney() {
      return [
        {
          iconImg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAMAAADUivDaAAAAZlBMVEUAAAD/uQP/uwD/ugL/uQL/uQH/ugH/ugP/uQL/uQL/ugL/ugL/uQD/uQb/uAD/uQH/ugH/uQL/iwP/88f/3Hr/4Ib/8L//zkn/xi3/wBj/vAv/7bL/6aj/5Zr/4Yz/2Gv/0ln/yTlrrmsEAAAAEXRSTlMATAv17LazU+bfnnc3LBnNzHvlhCkAAACvSURBVFjD7dC5DoMwEEVRxws7JA4EAtn//ydTIKZ7eLAipWBuOcWR3ihJkqR/lTrrA1mXKpxOPKtEQ4KEoAFXeHZoi+MTDhCWT1hA+A0B4rwhIYKE35AQQvyKGC5LXSQx9u3SO5LoSLhHDrm21CeSeJDwjHznQEI/rhIGCdONiNd8MYA4wk901DRfToAoPbsSEDrnCrlWoDrjCVmtYE1hwoApGrWWrg6BKq0kSdpnX5ZAYFOGtoh7AAAAAElFTkSuQmCC',
          title: '办信用卡，送红包',
          desc: '自己办卡，邀请他人均可获得现金奖励',
          completeText: '会员奖励：13天',
        },
        {
          iconImg: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABCCAMAAADUivDaAAABC1BMVEUAAAD/zgD/zwD/zQD/zQD/zQD/zgD/yQD/zQD/owD/zQD/zgD/zQD/zgD/zQD/zgD/zQD/zgD/zgD/zgD/ygD/xwH/zgD/tgD/wgD/zgD/zQD/zwD/zAD/zgD/tgL/ugL/zQD/uQH/zQD/uQH/zQD/uwL/zgD/zgD/zQD/zgD/zgD/uAD/3gD/uQL/zQD/iwP/iwP/iwP/iwP/uQH/ugL/iwL/ugH/iwP/lwP/zQD/zQD/ugL/ugL/iwT/zQD/uQP/jwP/jAP/jAT/igX/twD/tQD/jQD/zQD/uQL/iwL/vwD/wQH/pwH/ygD/xAD/vAD/88f/5Hn/0A//uQH/657/4nD/1zj/0yL/lQJOxpPNAAAAR3RSTlMA6lD+9tjOBPwC7eLTyKyPjHhxVDotGxYI8GlkRjH+8ufRwrW1l2xfREEkEgv6+Pb17u3b2dbHxL+nlYyJiH9jYllFMCcmJn6lSfIAAAGbSURBVFjD7dVnb8JADAZgZzRl7z1LKbN77z18pIwWuv7/L2kAKYB6wRf42DyfIp3yJrJlHzgcjiXEKkfFYunqGRa1WWZj25ersJDkITOVF8pwn7ApFVjALZtWODvYPb5Ogi0lNqPdMuw/2Kolm1VoDe090iXMRhMw8sSGBj391aDrPdYaOQVKBBG1bMp4irOB3u2gqdNr/wwzXoCQV9AgZdwQN9+f0I2C3APFjyNaKI083fYdUIJI8MWBEEaKKhMRVSS5ojBPTUKamgdrUQVFrFgP3aoXxUQsZzOEgry830jV5bAPheU4Eevmaf+rj6QgJ2LHPP14e0eSnxMxKeSnSITEiQig6VtBkos75QQ6oqmgHR7g2EA7/Nxl40IbgsBzgzbEgMcdQGEa8KXEy9GwHNSQYFsCYE0Wmva1JMyxFVSRRC3PREgla0mSiYgwkNwenCsOtBVizpeOCNiJSEvqVCfPNc72piIaRpObuWwmU4vGEgA5TkuJCCnFW9GqEUbzjRfTn8/FFHPZUOQLvyRpdc61n/b4quBw/Cu/FQ11E6CfefkAAAAASUVORK5CYII=',
          title: '证券理财，送现金红包',
          desc: '自己及邀请他人办理理财业务，均可获得现金',
          completeText: '会员奖励：13元',
        },
      ];
    },
    acvityVipType() {
      return acvityVipType();
    },
    acvityCardType() {
      return acvityCardType();
    },
    acvityPacketType() {
      return acvityPacketType();
    },
  },
  methods: {
    async getStatus() {
      let res;
      const { userId } = this;
      console.log(userId);
      try {
        res = await getTaskStatusApi({ userId });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1) {
        const { results } = res;
        this.advertisements = results?.advertisements || [];
        this.vipDays = results?.userTotalVipCount || 0;
      } else {
        this.advertisements = [];
      }
    },
    async getUserInfo() {
      let res;
      const { userId } = this;
      const header = { cuserId: userId, version: '5.0.1' };
      let { appInfo } = this;
      if (isString(this.appInfo)) {
        appInfo = JSON.parse(appInfo);
      }
      let { osType } = appInfo;
      try {
        res = await getUserInfoApi(
          { cuserId: userId, osType },
          header,
        );
      } catch (e) {
        console.log(e);
        this.loadingVisible = false;
        return this.$toast({ content: e.desc });
      }
      this.loadingVisible = false;
      if (res?.code === 1) {
        const { results } = res;
        this.userInfo = results;
      } else if (res?.code === 2) {
        this.modelVisible = true;
        return this.$toast({ content: '请登录后访问' });
      } else {
        return this.$toast({ content: res.desc || '网络异常' });
      }
    },
    clickAction(type, isComplete) {
      if (type === 'video') {
        if (isComplete) {
          return this.$toast({ content: '今日视频观看次数已达上限' });
        }
        watchVideo(); // 调取观看视频
      }
    },
    dowloadImage() {
      const { host, protocol } = window.location;
      const { qrcodeUrl } = this;
      const imgUrl = this.dialogDesc.qrcodeUrl;
      window?.ios?.saveImageToPhotosAlbum(imgUrl);
      window?.android?.saveWebImg(imgUrl);
    },
    cardClickAction(data) {
      const { type } = data;
      if (type === 'vip') {
        this.$router.push({
          path: 'challenge',
          query: {
            userId: this.userId,
          },
        });
      }
      console.log(type);
      if (type === 'video') {
        window._czc && window._czc.push(['_trackEvent', '看视频赢会员', '点击', '任务中心']);
        watchVideo(); // 调取观看视频
      }
    },
    openDialog(data, data1) {
      this.dialogType = data.type;
      console.log(data1);
      const {
        type, shareUrl, favicon, desc1, qrcodeUrl,
      } = data;
      const { taskId, taskName, desc } = data1;
      console.log(data);
      this.dialogDesc = {
        desc1,
        qrcodeUrl,
      };
      if (type === 'invite') {
        let config = shareConfig(taskName, shareUrl, desc, favicon);
        if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
          window?.ios?.share(JSON.stringify(config));
        }
        if (/android/i.test(navigator.userAgent)) {
          console.log(JSON.stringify(config));
          console.log(window?.android?.configShare, 'configShare');
          window?.android?.share(JSON.stringify(config));
        }
      }
      if (type === 'examine') {
        const { taskName } = data1;
        this.taskInfo = {
          taskName,
          taskId,
        };
        this.examineDesc = data.desc;
      }
      if (type === 'rule') {
        this.ruleDesc = data.rule;
      }
      this.visible = (type === 'rule' || type === 'examine');
    },
    jumpAction(status) {
      if (status === 'gold') {
        return this.$router.push({
          path: '/gold/eden',
        });
      }
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        if (status === 'money') {
          window.location.href = 'yyjz://Wallet/changePurse';
        }
        if (status === 'vip') {
          window.location.href = 'yyjz://Vip/center';
        }
      }
      if (/android/i.test(navigator.userAgent)) {
        if (status === 'money') {
          window.android.goToActivity2('jz://com.caiyi.accounting/com.caiyi.accounting.jz.mineWallet.WinMoneyWalletActivity');
        }
        if (status === 'vip') {
          window.android.goToActivity2('jz://com.caiyi.accounting/com.caiyi.accounting.jz.vip.VipCenterActivity');
        }
      }
    },
    imageChange(val) {
      console.log(val);
      this.uploadImage.push(val);
    },
    removeImage(index) {
      this.fileList.splice(index, 1);
      this.uploadImage.splice(index, 1);
    },
    imageUpload(data, index) {
      let { file, tempUrl } = data;
      this.fileList = this.fileList.concat(Array.from(file));
      console.log(this.fileList);
    },
    submitAction() {
      const data = this.fileList;
      let param = new window.FormData();
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      data.forEach((item) => {
        param.append('files', item);
      });
      if (!data.length) {
        param.append('files', null);
      }
      param.append('cuserId', this.userId);
      param.append('phone', this.phone);
      if (this.taskInfo) {
        const { taskId, taskName } = this.taskInfo;
        console.log(this.taskInfo);
        param.append('taskId', taskId);
        param.append('taskName', taskName);
      }
      const myreg = /^(((13[0-9]{1})|(14[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(15[0-3]{1})|(15[4-9]{1})|(18[0-9]{1})|(199))+\d{8})$/;
      if (!this.phone) {
        return this.$toast({ content: '请填写手机号!', duration: 2000 });
      }
      if (this.phone.length < 11 || !myreg.test(this.phone)) {
        return this.$toast({ content: '手机号填写有误,请重新输入!', duration: 2000 });
      }
      if (!data.length) {
        return this.$toast({ content: '请上传图片!', duration: 2000 });
      }
      window._czc && window._czc.push(['_trackEvent', '任务中心', '点击', '收集用户信息']);
      this.dialogType = 'approve';
      axios.post('/api/taskCenter/examine', param, config).then((res) => {
        console.log(res);
        const { data } = res;
        if (data.code === 1) {
          // this.$toast({
          //   content: '提交成功',
          //   duration: 3000,
          // });
        }
        this.submiting = false;
      }).catch((e) => {
        this.submiting = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .vip-page {
    font-family: PingFangSC-Regular, PingFang SC;
    background: #F5F5F5;
    padding-bottom: 100px;
  }
  .vip-header {
    width: 100%;
    height: 248px;
    background-color: orange;
    position: relative;
    .user-msg {
      position: absolute;
      // width: 100%;
      display: flex;
      top: 51px;
      left: 25px;
      align-items: center;
      .avator {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background-color: #fff;
        position: relative;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
      .user-text {
        margin-left: 18px;
        color: #fff;
        .username {
          font-size: 28px;
          line-height: 40px;
          font-weight: 600;
        }
      }
    }
  }
  a {
    text-decoration: none;
  }
  .vip-userinfo {
    margin-left: 26px;
    margin-right: 26px;
    height: 154px;
    background: #FFFFFF;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: -77px;
    position: relative;
    z-index: 22;
    ul {
      display: flex;
      li {
        flex: 1;
        text-align: center;
        p.num {
          font-size: 32px;
          font-weight: 800;
          line-height: 45px;
          color: #333333;
          margin-top: 32px;
          span.bold {
            color: #EF5C53;
          }
        }
        p.text {
          font-size: 26px;
          color: #999999;
          line-height: 37px;
          margin-top: 9px;
        }
      }
    }
  }
  .vip-main {
    margin: 0 25px;
  }
  .vip-bottom {
    width: 100%;
    margin-top: 30px;
  }
  .y-button--danger {
    width: 126px;
    height: 54px;
    text-align: center;
    // line-height: 54px;
    color: #fff;
    background: linear-gradient(180deg, #FC1923, #FE9437);
    box-shadow: 2px -1px 24px 0px rgba(255, 250, 182, 0.78);
    border-radius: 42px;
    font-size: 24px;
    font-weight: 500;
  }
  .task-bottom {
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 24px;
    font-weight: 400;
    color: #999999;
    line-height: 38px
  }
  .welfare {
    z-index: 100;
    /deep/.y-dialog-main {
      height: auto;
      width: 623px;
      background: linear-gradient(0deg, #FEF3E4 55.00000000000001%, #FADED4 100%);
      border-radius: 24px;
      padding-bottom: 50px;
      .y-dialog {
        // max-height: 800px;
        // overflow: auto;
      }
      .close {
        width: 60px;
        height: 60px;
        background-image: url('~@/assets/images/close.png');
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: -120px;
        right: -30px;
      }
      .title {
        width: 560px;
        height: 170px;
        background-size: cover;
        background-image: url('~@/assets/images/ribbon.png');
        position: absolute;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 42px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 200px;
        text-align: center;
      }
      .dialog-desc {
        margin-top: 80px;
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #EA613D;
        line-height: 60px;
        // text-align: center;
        margin-left: 35px;
        margin-right: 35px;
        a.img-url {
          color: #4EB3FF;
          line-height: 35px;
          display: block;
          font-size: 24px;
        }
        img {
          max-width: 100%;
        }
        &.desc-left {
          text-align: center;
        }
        &.dialog-desc-content {
          margin-top: 31px;
        }
      }
      .qrcode {
        width: 274px;
        margin: 45px auto 0;
        img {
          width: 100%;
        }
      }
    }
    .dowload-btn {
      width: 401px;
      height: 72px;
      background: linear-gradient(181deg, #FC1923, #FE9437);
      box-shadow: 2px -1px 24px 0px rgba(255, 250, 182, 0.78);
      border-radius: 36px;
      line-height: 72px;
      text-align: center;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      margin: 58px auto 0;
    }
  }
  .vip-main-head {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    align-items: center;
    .title {
      font-size: 32px;
      font-weight: 800;
      color: #333333;
      line-height: 45px;
    }
    .desc {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      line-height: 33px;
    }
  }
  .avator-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    right: -10px;
    bottom: 0;
    img {
      width: 100%;
    }
  }
  .tips-text {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #EA613D;
    line-height: 50px;
    text-align: center;
    margin-top: 10px;
  }
  .y-item {
    display: flex;
    align-items: center;
    &:nth-child(2) {
      margin-top: 73px;
    }
    .y-item__label {
      width: 120px;
      font-weight: 600;
    }
    .y-item__content {
      width: 376px;
      .y-input {
        width: 100%;
        input {
          display: block;
          height: 67px;
          background: #FFFFFF;
          width: 100%;
          border-radius: 8px;
          border: 2px solid #F26858;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #EF5C53;
          line-height: 45px;
          font-size: 26px;
          padding-left: 15px;
          outline: none;
          -webkit-appearance: none;
          &::placeholder {
            font-size: 26px;
            color: #EF5C53;
          }
        }
      }
    }
  }
  .upload-image {
    display: flex;
    .image {
      width: 120px;
      height: 120px;
      text-align: center;
      margin-right: 35px;
      position: relative;
      .close {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #EF5C53;
        position: absolute;
        right: -17.5px;
        top: -17.5px;
        &::before, &::after {
          position: absolute;
          content: '';
          background-color: #fff;
          left: 50%;
          top: 50%;
          margin-left: -1px;
          margin-top: -10px;
          width: 3px;
          height: 20px;
        }
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    /deep/ .avatar-uploader {
      width: 120px;
      height: 120px;
      background: #FFFFFF;
      border-radius: 8px;
      border: 2px solid #EF5C53;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 60px;
      }
    }
  }
  .button {
    width: 331px;
    margin: 58px auto 0;
    text-align: center;
    .y-button {
      width: 100%;
      height: 67px;
    }
  }
  .user-model {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 1PX;
      height: 63px;
      background: #e7e7e7;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .y-model {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 20;
  }
</style>
